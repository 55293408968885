<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />
    <b-form @submit.prevent>
      <validation-observer ref="validationPixelRules">
        <b-tabs
          pills
        >
          <b-tab active>
            <!-- title -->
            <template #title>
              <feather-icon
                icon="SettingsIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">{{ $t('Settings') }}</span>
            </template>
            <b-card>
              <div class="head-card">
                <div class="head-card-top">
                  <h4>{{ $t('pixels.create') }}</h4>
                  <feather-icon
                    v-b-tooltip.hover.top="$t('Close')"
                    icon="XIcon"
                    size="18"
                    class="toggle-close cursor-pointer"
                    @click="$router.go(-1)"
                  />
                </div>
                <hr>
              </div>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    :label="$t('pixels.title')"
                    label-for="i-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length == 0 || 'is-invalid'"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="LayoutIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-name"
                          v-model="pixel.name"
                          :placeholder="$t('pixels.title')"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="name"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group>
                    <b-form-checkbox
                      v-model="all_pages"
                      :selected="all_pages"
                    >
                      {{ $t('pixels.all_pages') }}
                    </b-form-checkbox>
                  </b-form-group>

                  <b-form-group
                    :label="$t('profile.notes')"
                    label-for="i-notes"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="FileTextIcon" />
                      </b-input-group-prepend>
                      <b-form-textarea
                        id="i-notes"
                        v-model="pixel.notes"
                        :placeholder="$t('profile.notes')"
                      />
                    </b-input-group>
                  </b-form-group>

                  <b-form-group
                    label="Status"
                    label-for="i-status"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Status"
                      rules="required"
                    >
                      <v-select
                        id="i-status"
                        v-model="status"
                        placeholder="Choose the status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="statuses"
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  md="12"
                  class="d-flex align-items-center justify-content-end mt-1"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    class="mr-0 mr-sm-1"
                    @click="$router.go(-1)"
                  >
                    <feather-icon
                      icon="ChevronLeftIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('form.back') }}</span>
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="pixelCreate"
                  >
                    <span class="align-middle">{{ $t('form.save') }}</span>
                    <feather-icon
                      icon="ChevronRightIcon"
                      class="ml-50"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-tab>
          <!--/ general tab -->
        </b-tabs>
      </validation-observer>
    </b-form>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import options from '@/libs/options'
import {
  BRow,
  BCol,
  BTabs,
  BTab,
  BCard,
  BForm,
  BFormGroup,
  // BInputGroupAppend,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  required,
  integer,
  min,
} from '@validations'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import { getUserData } from '@/auth/utils'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
    Breadcrumbs,
    BForm,
    BFormGroup,
    // BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BButton,
    UserToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      authUserData: getUserData(),
      pageTitle: '',
      pageSubtitle: '',
      account: {},
      min,
      required,
      integer,
      pixel: {
        user_id: this.$route.params.userId,
        name: '',
        notes: '',
        status: null,
        all_pages: null,
      },
      breadcrumbItems: [],
      status: 'inactive',
      all_pages: false,
      ...options,
    }
  },
  created() {
    this.getUserData()
  },
  methods: {
    pixelCreate() {
      this.$refs.validationPixelRules.validate().then(success => {
        if (success) {
          this.pixel.all_pages = this.all_pages
          this.pixel.status = this.status.value
          useJwt.createPixel(this.pixel)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Pixel created',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$router.push(`/users/${this.$route.params.userId}/pixels`)
        }
      })
    },
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || {}
      this.pageTitle = this.account.name
      this.pageSubtitle = this.account.email
      this.setBreabcrumbs()
    },
    async setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: this.pageTitle,
          to: `/users/${this.$route.params.userId}`,
        },
        {
          text: 'Pixels',
          to: `/users/${this.$route.params.userId}/pixels`,
        },
        {
          text: this.$t('pixels.create'),
          active: true,
        },
      ]
    },
  },
}
</script>
